import React from 'react';
import * as THREE from "three";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
import GLTFLoader from 'three-gltf-loader';
import DRACOLoader from 'three-dracoloader';
import FBXLoader from 'three-fbxloader-offical';
// import FBXLoader from 'three-fbx-loader';
import OrbitControls from "three-orbitcontrols";

class Captiva extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fbx_loaded: 0
        };
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    componentDidMount() {
        //
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();

        //
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        this.scene = new THREE.Scene();
        
        // Add Renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setClearColor("#ffffff");
        this.renderer.setSize(width, height);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.mount.appendChild(this.renderer.domElement);
        
        // Add Camera
        this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        this.camera.position.z = 100.20262864534358;
        this.camera.position.y = 28.405803173687165;
        this.camera.position.x = -34.611505270325715;
        this.camera.lookAt(0, 0, 0);

        // Camera Controls
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.target.set(0, 0, 0);
        
        controls.enableZoom = true;
        controls.enableRotate = true;
        controls.enablePan = false;
        
        controls.maxPolarAngle = Math.PI / 2.4;
        controls.minPolarAngle = Math.PI / 7;
        controls.maxDistance = 200;
        controls.minDistance = 50;

        // Lights
        var light = new THREE.DirectionalLight(0xffffff, .3);
        light.position.set(-100, 100, 200);
        light.position.multiplyScalar(1.3);
        light.castShadow = true;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        var d = 300;
        light.shadow.camera.left = - d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = - d;
        light.shadow.camera.far = 1000;
        this.scene.add(light);

        light = new THREE.DirectionalLight(0xffffff, .3);
        light.position.set(100, 100, -200);
        light.position.multiplyScalar(1.3);
        light.castShadow = true;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        d = 300;
        light.shadow.camera.left = - d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = - d;
        light.shadow.camera.far = 1000;
        this.scene.add(light);

        this.ambientLight = new THREE.AmbientLightProbe(0xffffff, .7);
        this.scene.add(this.ambientLight);

        this.spotLight = new THREE.SpotLight(0xffffff, .5);
        this.spotLight.position.set(80, 100, 80);
        this.spotLight.distance = 0;
        this.spotLight.angle = 12;
        this.spotLight.penumbra = 0;
        this.spotLight.decay = 0;
        this.scene.add(this.spotLight);

        this.spotLight = new THREE.SpotLight(0xffffff, .5);
        this.spotLight.position.set(-80, 100, -80);
        this.spotLight.distance = 0;
        this.spotLight.angle = 12;
        this.spotLight.penumbra = 0;
        this.spotLight.decay = 0;
        this.scene.add(this.spotLight);

        this.pointLight = new THREE.PointLight(0xffffff, 1, 2);
        this.pointLight.position.set(0, 25, 0);
        this.pointLight.distance = 100;
        this.pointLight.decay = 2;
        this.scene.add(this.pointLight);

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, .5);
        hemiLight.position.set(0, 28, 0);
        this.scene.add(hemiLight);

        // Add Your 3D Models here
        // FBX

        const loader = new FBXLoader();

        loader.load('./fbx/captiva_v12.fbx', 
            (object) => {
                var envMap = new THREE.TextureLoader().load('./images/entorno-de-mapas-hdr-159098391.jpg');
                envMap.mapping = THREE.EquirectangularReflectionMapping;
                envMap.encoding = THREE.sRGBEncoding;
                object.traverse(function(child) {
                    if (child.isMesh) {                        
                        if (child.name == 'Lamina') {
                            child.material[1].color.set(0x830204);
                            child.material[1].envMap = envMap;
                            child.material[1].envMapIntensity = 1;

                            child.material[2].envMap = envMap;
                            child.material[2].envMapIntensity = 1;

                            child.material[3].envMap = envMap;
                            child.material[3].envMapIntensity = 1;
                        
                            child.material[0].color.set(0x656567);
                            child.material[0].specular.set(0x3c3c3c);
                            child.material[0].shininess = 10;
                            
                            child.material[4].color.set(0x656567);
                            child.material[4].specular.set(0x3c3c3c);
                            child.material[4].shininess = 10;
                        } else if (child.name == 'vidrios') {
                            child.material[1].envMap = envMap;
                            child.material[1].envMapIntensity = 1;
                            child.material[1].opacity = .4;

                            child.material[0].side = THREE.DoubleSide;
                        } else if (child.name == 'Parrilla') {
                            child.material.shininess = 100;
                        } else if (child.name == 'molduras') {
                            child.material[2].color.set(0x0e0e0e);
                            child.material[2].specular.set(0x0e0e0e);
                            child.material[2].shininess = 10;
                        } else if (child.name == 'LLantas') {
                            child.material[3].color.set(0x0e0e0e);
                            child.material[3].specular.set(0x151515);
                            child.material[3].shininess = 70;

                            child.material[0].color.set(0xffffff);
                            child.material[0].specular.set(0xffffff);
                            child.material[0].shininess = 100;
                        } else if (child.name == 'Faro') {
                            // child.material[0].color.set(0xffffff);                            
                        } else if (child.name == 'Stop3') {
                            child.material[0].color.set(0xff0000); 
                        } else if (child.name == 'Stop') {
                            // child.material[3].color.set(0xff0000);
                            // child.material[3].combine = false;
                            // child.material[3].alphaMap = 1;

                            child.material[3].color.set(0xda0407);
                            // child.material[3].transparent = false;
                            // child.material[3].specular.set(0xffffff);
                            // child.material[3].shininess = 0;
                        } else if (child.name == 'Stop2') {
                            // child.material[3].color.set(0xff0000);
                            child.material[0].color.set(0xda0407);
                            
                            child.material[1].color.set(0x7e7a75);
                        } 

                        if (child.name == 'Stop') {
                            child.visible = true;
                        } else {
                            child.visible = true;
                        }

                        // console.log(child.name);
                    }
                });
                object.scale.set(5, 5, 5);
                object.position.set(0, -15, 0);
                this.scene.add(object);
                this.setState({
                    fbx_loaded: 100
                });
            },
            (xhr) => {
                // console.log(`${( xhr.loaded / xhr.total * 100 )}% loaded`);
                var porcentaje = (xhr.loaded / xhr.total) * 100;
                this.setState({
                    fbx_loaded: porcentaje * 100
                });
            },
            (error) => {
                // console.error('An error happened', error);
            }
        );


        this.planeTexture = new THREE.TextureLoader().load("./images/piso_sombra.png", 
            (texture) => {
                // console.log('texture loaded');
            },
            (xhr) => {
                // console.log(`${( xhr.loaded / xhr.total * 100 )}% loaded`);
            },
            (error) => {
                // console.error('An error happened', error);
            }
        );

        this.planeMaterial = new THREE.MeshBasicMaterial({ map: this.planeTexture, transparent: false });
        this.planeGeometry = new THREE.PlaneGeometry(130, 61);
        this.planeMesh = new THREE.Mesh(this.planeGeometry, this.planeMaterial);
        this.planeMesh.rotation.x = -Math.PI / 2;
        this.planeMesh.position.set(0, -15.2, 0);
        this.planeMesh.material.opacity = .7;
        this.scene.add(this.planeMesh);
        
        // Render Scene
        this.renderScene();
        
        // Start Animation
        this.start();
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    }
    
    stop = () => {
        cancelAnimationFrame(this.frameId);
    }
    
    animate = () => {
        // Animate Models Here
        // ReDraw Scene with Camera and Scene Object
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate);
    }
    
    renderScene = () => {
        if (this.renderer) this.renderer.render(this.scene, this.camera);
    }

    componentDidUpdate() {
        
    }

    onWindowResize = () => {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        if (this.renderer)  {
            this.camera.aspect = this.w / this.h;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.w, this.h);
        }
    }

    onClickButtonVideo = (event) => {
        
    }
    
    render () {
        return(
            <>
                <div style={{ width: "100vw", height: "100vh" }} ref={mount => { this.mount = mount }} />
                <div className='container-cargador' style={{ display: (this.state.fbx_loaded == 100) ? 'none' : 'flex' }}>
                    <div className='barra-cargador'>
                        <span style={{ width: this.state.fbx_loaded + '%' }}></span>
                    </div>
                </div>
            </>
        );
    }

}

export default Captiva;