import React from 'react';
import ReactDOM from 'react-dom';
import Captiva from './components/Captiva';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fonts from './css/fonts.css';
import Styles from './css/styles.css';
import {useSpring, animated} from 'react-spring';
import {Controller} from 'react-spring/renderprops';
import {TweenMax} from "gsap";
import {Quart, Quint} from "gsap";
import Utils from "./helpers/Utils";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import DOMPurify from 'dompurify';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  }

  componentDidMount(){
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (isMobile || isTablet) {
    }
  }

  render() {
    return (
      <div className="App">
        <Captiva></Captiva>
      </div>
    );
  }
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);